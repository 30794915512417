<script setup lang="ts">
const variantList = ["new", "fishing", "exclusive"] as const;
const { t } = useT();

const BADGE_TYPE = {
	new: {
		text: t("game new"),
		bg: "var(--cartagena)",
		icon: ""
	},
	fishing: {
		text: t("game fish"),
		bg: "var(--cucuta)",
		icon: "16/fish"
	},
	exclusive: {
		text: t("exclusive"),
		bg: "var(--chimoio)",
		icon: "20/entries"
	}
};

const props = defineProps<{
	variant: (typeof variantList)[number];
}>();

const type = computed(() => BADGE_TYPE[props.variant]);
</script>
<template>
	<div class="wrap">
		<ABadge :background="type.bg" variant="skew" autosize>
			<NuxtIcon v-if="type.icon" filled :name="type.icon" />
			<AText :modifiers="['uppercase', 'bold']" class="text" variant="ternopil"> {{ type.text }}</AText>
		</ABadge>
	</div>
</template>

<style scoped lang="scss">
.wrap {
	.badge {
		display: flex;
		align-items: center;
		flex-direction: row;
		gap: gutter(0.5);

		&.skew {
			border-radius: 3px;
			height: 20px;
			transform: skewX(-20deg);
			padding: 0 gutter(0.7);

			&::before {
				display: none;
			}
		}
	}
}
</style>
